import { ResourceState } from 'types'

type StateKeys = ResourceState | 'exporting'

/**
 * Interpolated translations:
 *
 * i18n-tasks-use t('resource_state.started')
 * i18n-tasks-use t('resource_state.submitted')
 * i18n-tasks-use t('resource_state.submitting')
 * i18n-tasks-use t('resource_state.needs_corrections')
 * i18n-tasks-use t('resource_state.flagged')
 * i18n-tasks-use t('resource_state.rejected')
 * i18n-tasks-use t('resource_state.approved')
 * i18n-tasks-use t('resource_state.active')
 * i18n-tasks-use t('resource_state.exporting')
 * i18n-tasks-use t('resource_state.inactive')
 * * i18n-tasks-use t('resource_state.pending_review')
 */
export const resourceStatusLabels: { [K in StateKeys]: string } = Object.freeze(
  {
    started: 'resource_state.started',
    submitting: 'resource_state.submitting',
    submitted: 'resource_state.submitted',
    needs_corrections: 'resource_state.needs_corrections',
    flagged: 'resource_state.flagged',
    rejected: 'resource_state.rejected',
    approved: 'resource_state.approved',
    active: 'resource_state.active',
    exporting: 'resource_state.exporting',
    inactive: 'resource_state.inactive',
    pending_review: 'resource_state.pending_review',
  }
)

export enum StarTheme {
  Dark,
  Light,
}

export enum TaskState {
  None = 'none',
  Processing = 'processing',
  Working = 'working',
  Queued = 'queued',
  Success = 'success',
  Completed = 'completed',
  Error = 'error',
}

export enum ActionResponse {
  Successful,
  Unsuccessful,
  Error,
}

export enum ReviewStatus {
  Approved = 'approved',
  Flagged = 'flagged',
  Rejected = 'rejected',
}

export enum ResourceStatus {
  Processing = 'processing',
  Successful = 'successful',
  ActionNeeded = 'action-needed',
  Rejected = 'rejected',
  AcceptChanges = 'accept_changes',
  Inactive = 'inactive',
}

export enum ResourceListActionType {
  Default = 'default',
  Focusable = 'focusable',
  Selectable = 'selectable',
  Acceptable = 'acceptable',
  Bulk = 'bulk',
  Approved = 'approved',
}

export enum GridSize {
  Fill = 'fill',
  XSmall = 'xs',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  XLarge = 'xl',
}

export enum ProgramType {
  All = 'all',
  PPD = 'ppd',
  WO = 'wo',
}

export enum ContentType {
  All = 'all',
  Bundle = 'bundle',
  Vector = 'vector',
  Photo = 'photo',
  Video = 'video',
  Png = 'png',
  Psd = 'psd',
}

export enum LicenseType {
  All = 'all',
  Pro = 'pro',
  Free = 'free',
  Editorial = 'editorial',
}

export enum Period {
  Week = 'week',
  Month = 'month',
  MTD = 'mtd',
  Quarter = 'quarter',
  HalfYear = 'half_year',
  YTD = 'ytd',
  Lifetime = 'lifetime',
}

export enum ReviewType {
  Auto = 'auto',
  Manual = 'manual',
}

export enum BoolType {
  True = 'true',
  False = 'false',
}

export enum Availability {
  Available = 'available',
  NotAvailable = 'not_available',
}

export enum IdVerificationStatusFilter {
  All = 'all',
  Verified = 'verified',
  NeedsReview = 'in-review',
  NotVerified = 'not-verified',
}

export enum DistributionStatusLabel {
  Ready = 'ready',
  Transferring = 'transferring',
  Transferred = 'transferred',
  TransferFailed = 'transfer_failed',
}

export type MarketplaceResource = {
  resource_id: string
  transfer_state: string
}
