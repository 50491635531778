import {
  Category,
  GetApiV1ResourcesQueryParams,
  Release,
  Resource,
  ResourceMetadataTemplate,
} from 'api'
import { ContentType, TaskState } from 'constants/resources'
import { captureError, getErrorMsg } from 'helpers/error'
import { initFilters } from 'helpers/filters'
import { SpecialCharsRegex } from 'helpers/strings'
import { t } from 'i18next'
import { isEqual } from 'lodash'
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
  toJS,
  when,
} from 'mobx'
import qs from 'qs'
import { FileUpload, ItemFilter, ResourceState } from 'types'
import Api from 'util/api'
import { DIContainer } from 'util/di'
import AuthStore from './AuthStore'
import ResourceStore from './ResourceStore'
import UploadStore from './UploadStore'

export default class AddDataStore {
  api: Api
  AuthStore: AuthStore
  UploadStore: UploadStore
  ResourceStore: ResourceStore
  state: ResourceState[]
  metadataInterval: number | undefined = undefined
  defaultState: ResourceState[] = ['started']
  defaultFilter: ItemFilter = {
    file_type: [],
    category_ids: [],
    content_type: 'all',
    program_type: 'all',
    license: 'all',
    sort: 'newest',
  }

  constructor(container: DIContainer<Record<string, unknown>>) {
    makeObservable(this)
    this.api = container.find('api')
    this.AuthStore = container.find('AuthStore')
    this.UploadStore = container.find('UploadStore')
    this.ResourceStore = container.find('ResourceStore')
    this.state = this.defaultState

    when(
      () => Boolean(this.AuthStore.session),
      () => {
        if (this.AuthStore.session?.hasFeature('file_type_filter')) {
          delete this.defaultFilter.content_type
          delete this.filter.content_type
        }
      }
    )
  }

  @observable filter: ItemFilter = initFilters(this.defaultFilter)
  @observable pollingResourceIds: string[] = []
  @observable metadataState: TaskState = TaskState.None
  @observable findReplaceState: TaskState = TaskState.None
  @observable invalidCharState: TaskState = TaskState.None
  @observable titleTermIsProhibited = false
  @observable keywordTermIsProhibited = false
  @observable isProhibitedTermsModalOpen = false
  @observable selectedMarketplaces: string[] = []
  @observable updatedAIGenerated = false

  @action saveTemplate = async (
    template: ResourceMetadataTemplate
  ): Promise<void> => {
    try {
      const method = template.id ? 'PATCH' : 'POST'
      const path = template.id
        ? `/api/v1/resource_metadata_templates/${template.id}`
        : '/api/v1/resource_metadata_templates'

      await this.api.request(method, path, {
        body: { ...template },
      })
    } catch (e) {
      captureError(e, 'Error while saving template - stores/AddDataStore.ts')
    }
  }

  @action deleteTemplate = async (
    id: ResourceMetadataTemplate['id']
  ): Promise<void> => {
    try {
      await this.api.request(
        'DELETE',
        `/api/v1/resource_metadata_templates/${id}`
      )
    } catch (e) {
      captureError(e, 'Error while deleting template - stores/AddDataStore.ts')
    }
  }

  @action applyTemplate = ({
    title,
    keywords,
    license,
    release_ids,
    ai_generated,
    ai_generator,
  }: ResourceMetadataTemplate): void => {
    this.setTitle(title ?? '')
    this.setKeywords(keywords ?? [])
    this.updateReleases(release_ids ?? [])
    this.setAIGenerated(ai_generated ?? false)
    this.setAIGenerator(ai_generator ?? '')

    if (license) {
      this.setLicense(license)
    } else {
      this.removeLicense()
    }
  }

  @action updateReleases = (releaseIds: string[]): void => {
    const { updateSelected } = this.ResourceStore
    updateSelected(res => (res.release_ids = releaseIds))
  }

  @action removeRelease = (release: Release): void => {
    const { updateSelected } = this.ResourceStore

    updateSelected(res => {
      if (res.release_ids?.length) {
        const idx = res.release_ids.findIndex(x => x === release.id)

        if (idx > -1) {
          res.release_ids.splice(idx, 1)
        }
      }
    })
  }

  @action removeAllReleases = (): void => {
    const { updateSelected } = this.ResourceStore
    updateSelected(res => (res.release_ids = []))
  }

  @action resetFilters = (): void => {
    this.filter = { ...this.defaultFilter }
  }

  @action setFilterNonce = () => {
    const filter = { ...this.filter }
    filter.nonce = Math.random().toString(10)
    this.setFilter(filter, true)
  }

  @action setKeywords = (keywords: string[]): void => {
    const { updateSelected } = this.ResourceStore
    const filtered = keywords
      .map(kw => kw.toLowerCase())
      .filter(kw => Boolean(kw.match(SpecialCharsRegex.Strict)))
    updateSelected(res => (res.keywords = Array.from(new Set(filtered))))
  }

  @action addKeyword = (word: string): void => {
    const { updateSelected } = this.ResourceStore
    updateSelected(resource => {
      const keywords = resource.keywords.slice()
      keywords.push(word)

      resource.keywords = [
        ...Array.from(new Set(keywords.map(kw => kw.toLowerCase()))),
      ] as string[]
    })
  }

  @action dragKeyword = (from: number, to: number): void => {
    const { updateSelected } = this.ResourceStore
    updateSelected(resource => {
      const keywords = resource.keywords.slice()
      const el = keywords[from]
      keywords.splice(from, 1)
      keywords.splice(to, 0, el)
      resource.keywords = keywords
    })
  }

  @action removeKeyword = (word: string): void => {
    const { updateSelected } = this.ResourceStore
    updateSelected(resource => {
      const keywords = resource.keywords.slice().map(kw => kw.toLowerCase())
      const idx = keywords.indexOf(word.toLowerCase())

      if (idx > -1) {
        keywords.splice(idx, 1)
        resource.keywords = keywords
      }
    })
  }
  @action removeAllKeywords = (keywords: string[]) => {
    const { updateSelected } = this.ResourceStore

    updateSelected(resource => {
      if (this.ResourceStore.selectedResources.length > 1) {
        keywords.forEach(keyword => {
          this.removeKeyword(keyword)
        })
      } else {
        resource.keywords = []
      }
    })
  }

  @action setFilter = (filter: ItemFilter, syncUrl = false): void => {
    if (!isEqual(this.filter, filter)) {
      this.ResourceStore.deselectAll()
      this.filter = filter

      if (syncUrl) {
        const url = qs.stringify(this.filter, {
          encode: false,
          arrayFormat: 'comma',
          addQueryPrefix: true,
        })
        history.pushState({}, '', url)
      }
    }
  }

  @action filterFromUrl(search: string, { isWO }: { isWO: boolean }): void {
    const filterParams = new URLSearchParams(search)

    if (Object.keys(filterParams).length > 0) {
      const newFilter = Object.keys(this.filter).reduce(
        (filter: ItemFilter, current: string): ItemFilter => {
          if (current !== 'nonce' && isWO && current !== 'program_type')
            filter[current] =
              filterParams.get(current) || this.defaultFilter[current]
          return filter
        },
        {} as ItemFilter
      )

      this.setFilter(newFilter)
    }
  }

  @action setTitle = (title: string): void => {
    const { updateSelected } = this.ResourceStore
    updateSelected(resource => {
      resource.title = title
    })
  }

  @action setTitleTermIsProhibited = (state: boolean): void => {
    this.titleTermIsProhibited = state
  }

  @action setKeywordTermIsProhibited = (state: boolean): void => {
    this.keywordTermIsProhibited = state
  }

  @action setProhibitedTermsModalOpen = (state: boolean): void => {
    this.isProhibitedTermsModalOpen = state
  }

  @action setDescription = (description: string): void => {
    const { updateSelected } = this.ResourceStore
    updateSelected(resource => {
      resource.description = description
    })
  }

  @action setPreviews = (previews: string[]): void => {
    const { updateSelected } = this.ResourceStore
    const previewArr = previews.map(preview => {
      return {
        preview: preview,
      }
    })
    updateSelected(res => (res.previews = Array.from(new Set(previewArr))))
  }

  @action setAIGenerated = (value: boolean): void => {
    const { updateSelected } = this.ResourceStore

    updateSelected(resource => {
      resource.meta_data ||= {}
      resource.meta_data.ai_generated = value
    })
    this.updatedAIGenerated = value
  }

  @action setAIGenerator = (value: string): void => {
    const { updateSelected } = this.ResourceStore

    updateSelected(resource => {
      resource.meta_data ||= {}
      resource.meta_data.ai_generator = value
    })
    this.updatedAIGenerated = Boolean(value)
  }

  @action setMagicMetadata = (value: boolean): void => {
    const { updateSelected } = this.ResourceStore

    updateSelected(resource => {
      resource.magic_metadata = value
    })
  }

  @action setSourceFile = (file: string): void => {
    const { updateSelected } = this.ResourceStore
    updateSelected(resource => {
      resource.source_file.url = file
    })
  }

  @action setLicense = (license?: string): void => {
    if (!license) return

    const { updateSelected } = this.ResourceStore
    updateSelected(resource => {
      resource.license_set = true

      if (resource.content_type === ContentType.Bundle) {
        resource.pro = true
        resource.license = 'pro'
      } else {
        switch (license) {
          case 'editorial':
            resource.pro = false
            resource.magic_metadata = false
            resource.license = 'editorial'
            break

          case 'pro':
            resource.pro = true
            resource.license = 'pro'
            break

          default:
          case 'free':
            resource.pro = false
            resource.license = 'free'
        }
      }
    })
  }

  @action setCategory = (category?: Category): void => {
    if (!category) return

    const { updateSelected } = this.ResourceStore
    updateSelected(resource => {
      resource.category = category
    })
  }

  @action removeLicense = (): void => {
    const { updateSelected } = this.ResourceStore
    updateSelected(resource => {
      resource.license_set = false
      resource.pro = false
      resource.license = undefined
    })
  }

  @action setPollingResourceIds = (ids: string[]): void => {
    this.pollingResourceIds = Array.from(new Set(ids))
  }

  @action addPollingResourceIds = (ids: string[]): void => {
    this.pollingResourceIds = Array.from(
      new Set(this.pollingResourceIds.concat(ids))
    )
  }

  @action pollForLockedReason = async (): Promise<{
    submittedResources: Resource[]
  } | null> => {
    try {
      const pollingIds = toJS(this.pollingResourceIds)
      const response: Resource[] = await this.ResourceStore.api.request(
        'GET',
        '/api/v1/resources',
        {
          query: { ids: pollingIds },
        }
      )

      if (Array.isArray(response)) {
        response.forEach(res =>
          this.ResourceStore.setLockedReason([res.id], res.locked_reason)
        )

        const locked = response
          .filter(res => !!res.locked_reason)
          .map(res => res.id)

        const allUnlocked = response
          .filter(res => !res.locked_reason)
          .map(res => res.id)

        const newUnlocked: string[] = []
        pollingIds.forEach(id => {
          if (allUnlocked.includes(id)) {
            newUnlocked.push(id)
          }
        })

        runInAction(() => this.setPollingResourceIds(locked))

        const submittedResources = response.filter(
          resource =>
            resource.state === 'submitted' && newUnlocked.includes(resource.id)
        )

        return { submittedResources }
      }

      return null
    } catch (error) {
      captureError(
        error,
        'Error while fetching locked reasons - stores/AddDataStore.ts'
      )
      throw error
    }
  }

  @action pollMetadata = async (): Promise<void> => {
    try {
      const res = await this.ResourceStore.api.request(
        'GET',
        '/api/v1/resource_metadata/processing_csv_files'
      )

      if (res?.processing) {
        this.metadataState = TaskState.Processing
      } else {
        if (this.metadataState === TaskState.Processing) {
          this.metadataState = TaskState.Success
        }

        clearInterval(this.metadataInterval)
        this.metadataInterval = undefined
      }
    } catch (err) {
      this.metadataState = TaskState.Error
      captureError(
        err,
        'Error while fetching metadata - stores/AddDataStore.ts'
      )
      throw err
    }
  }

  @action setMetadataState = (state: TaskState): void => {
    this.metadataState = state
  }

  @action setFindReplaceState = (state: TaskState): void => {
    this.findReplaceState = state
  }

  @action setInvalidCharState = (state: TaskState): void => {
    this.invalidCharState = state
  }

  @action setSelectedMarketplaces = (marketplaces: string[]): void => {
    this.selectedMarketplaces = marketplaces
  }

  @action setResourceMarketplaceAccountIds = (marketplaces: string[]): void => {
    const { updateSelected } = this.ResourceStore
    updateSelected(resource => {
      if (resource) {
        resource.marketplace_account_ids = marketplaces
      }
    })
  }

  @computed get queryParams(): GetApiV1ResourcesQueryParams {
    const params: GetApiV1ResourcesQueryParams = { state: this.state }

    if (this.filter.file_type?.length) {
      params.file_type = this.filter.file_type
    }

    if (this.filter.category_ids?.length) {
      params.category_ids = this.filter.category_ids
    }

    if (this.filter.content_type !== 'all' && this.filter.content_type) {
      params.content_type = [this.filter.content_type]
    }

    if (this.filter.program_type !== 'all' && this.filter.program_type) {
      params.program_type = this.filter.program_type
    }

    if (this.filter.license !== 'all' && this.filter.license) {
      params.license = this.filter.license
    }

    if (this.filter.sort === 'newest') {
      params.sort_field = 'updated_at'
      params.sort_direction = 'desc'
    }

    if (this.filter.sort === 'oldest') {
      params.sort_field = 'updated_at'
      params.sort_direction = 'asc'
    }

    return params
  }

  @computed get hasUpdatedAIGenerated() {
    return this.updatedAIGenerated
  }

  initMetadataPolling = (): void => {
    if (!this.metadataInterval) {
      this.metadataInterval = window.setInterval(
        () => this.pollMetadata(),
        5000
      )
    }
  }

  onMetadataUploadSuccess = async (upload: FileUpload): Promise<void> => {
    const data = new FormData()
    data.set('csv_file', upload.file as Blob, upload.name)

    try {
      await this.ResourceStore.api.request(
        'POST',
        '/api/v1/resource_metadata',
        {
          body: data,
        }
      )

      this.UploadStore.updateFiles(upload, {
        status: 'success',
        progress: { totalPercent: 200 },
      })

      this.setMetadataState(TaskState.Processing)
      this.initMetadataPolling()
    } catch (e: unknown) {
      const errors = upload.errors || []

      errors.push(t(getErrorMsg(e, 'error') || 'error'))

      this.UploadStore.updateFiles(upload, {
        status: 'error',
        errors: errors,
        progress: { totalPercent: 200 },
      })
      captureError(e, 'Error while uploading metadata - stores/AddDataStore.ts')
    }
  }
}
